<template>
  <v-content class="content">
    <v-row class="px-1 pt-1 pb-0 mt-6 mx-4 rounded-lg box" style="background-color:#fff">
      <v-col>
        <v-row class="mb-2">
          <v-col cols="4" class="pa-0" style="font-size: 0.875rem; font-weight: bold; color: #808080; line-height: 1.8">
            ユーザーID
          </v-col>
          <v-col cols="8" class="pa-0">
            <p v-if="history && history.user_id">
              {{ history.user_id }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pa-0 mb-3" style="font-size: 0.875rem; font-weight: bold; color: #808080;  line-height: 1.8">
            ニックネーム
          </v-col>
          <v-col cols="8" class="pa-0">
            <p v-if="history && history.user_name">
              {{ history.user_name }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mb-1" @click="showQRDialog()">
            <qrcode
              :value="qrcodeurl"
              :options="{ width: 80, margin: 1 }"
            ></qrcode>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <p style="font-size: 0.625rem">
              ポイント取得の際は、QRコードをスタッフへご提示ください
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-1 pt-1 pb-0 mt-6 mx-4 rounded-lg box" style="background-color:#fff">
      <v-col>
        <v-row>
          <v-col cols="6" class="pa-0 mb-3" style="font-size: 0.875rem; font-weight: bold;">
            ポイント履歴
          </v-col>
          <v-col cols="6" class="pa-0 text-right" style="font-size: 0.625rem;">
            最終利用月から3ヵ月間を表示
          </v-col>
        </v-row>
        <template v-if="history && history.data !== ''">
          <v-row
            no-gutters
            align="center"
            v-for="item in history.data"
            :key="item.id"
          >
            <v-col cols="7" class="fontsize13">
              {{ item.create_date }}
            </v-col>
            <v-col cols="2" class="fontsize13">
              {{item.app_type_name}}
            </v-col>
            <v-col cols="3" class="text-right title font-weight-bold">
              {{ Number(item.point).toLocaleString() }} <span class="subtitle-1 font-weight-bold">p</span>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-col class="pa-0" style="font-size:0.75rem;">
            ポイント履歴はありません。
          </v-col>
        </template>
      </v-col>
    </v-row>

    <v-row class="px-1 pt-1 pb-0 mt-6 mx-4 rounded-lg box" style="background-color:#fff">
      <v-col>
        <v-row>
          <v-col class="pa-0 mb-3" style="font-size: 0.875rem; font-weight: bold;">
            ポイントについて
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <p
              class="caption"
              v-if="history && history.point_detail"
              v-html="history.point_detail"
            ></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-1 pt-1 pb-0 mt-6 mb-15 mx-4 rounded-lg box" style="background-color:#fff">
      <v-col>
        <v-row>
          <v-col class="pa-0 mb-3" style="font-size: 0.875rem; font-weight: bold;">
            お使いのスマートフォンについて
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <p class="caption">
              現在ご使用中のスマートフォンにて、機種変更ならびにアプリの再インストールをお考えのお客様へ。<br>
              同じOSへの変更の場合も異なるOSへの変更の場合も、現在取得しているポイントは引き継ぐことが可能です。
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- QR codeについてダイアログ -->
    <QRDialog ref="qrDialog" :qrdata="qrcodeurl"></QRDialog>
  </v-content>
</template>

<script>
import QRDialog from "@/views/point/QRDialog.vue";
export default {
  components: {
    QRDialog,
  },
  data() {
    if (history.point_detail) {
      this.history.point_detail = this.history.point_detail.replace(
        /\n/g,
        "<br/>"
      );
    }
    return {
      qrcodeurl: null,
      minute: 60, // QR code timeout 店舗アプリ側でもチェックしている。"QR code timeout"で検索すると見つかる。
      second: 0,
      selectTab: "user",
    };
  },
  props: ["history", "init"],
  methods: {
    showQRDialog() {
      this.$refs.qrDialog.open();
    },
    timer() {
      var self = this;
      clearInterval(this.promiseTimer);
      this.promiseTimer = setInterval(function () {
        if (self.minute !== 0 && self.second === 0) {
          self.second = 59;
          self.minute -= 1;
        } else if (self.minute === 0 && self.second === 0) {
          self.second = 0;
          self.minute = 0;
          if (self.selectTab === "user") {
            self.callDialog(
              "エラー",
              "QRコードの有効期限が切れました。再度表示しなおしてください。",
              2
            );
          }
          clearInterval(self.promiseTimer);
        } else {
          self.second -= 1;
        }
      }, 1000);
    },
    setTabName(val) {
      this.selectTab = val;
      if (this.minute === 0 && this.second === 0 && this.selectTab === "user") {
        this.callDialog(
          "エラー",
          "QRコードの有効期限が切れました。再度表示しなおしてください。",
          2
        );
      }
    },
  },
  mounted() {
    this.timer();
    this.qrcodeurl =
      process.env.VUE_APP_ADMIN_URL +
      "/points/" +
      this.init.user_id +
      "/edit?shopId=" +
      this.init.sid +
      "&shopName=" +
      this.init.shop_name +
      "&nowDate=" +
      Date.parse(new Date());
    console.log(this.qrcodeurl);
  },

  async created() {
    this.init = this.storageGet("*");

    try {
      //ポイント情報取得API
      var req = {
        params: {
          sid: this.init.sid,
          key: this.init.key,
          device_id: this.init.device_id,
          device_uid: this.init.device_uid,
          os: this.init.os,
          app_version: this.init.app_version,
        },
      };

      //ポイント履歴取得API
      var res_history = await this.apiCall("/point/history", req);
      if (!res_history) return false;
      this.$set(this, "history", res_history);
    } catch (e) {
      this.loading = false;
      this.callDialog(
        "接続エラー",
        "一時的なエラーです。ネットワークの状態が不安定です。再度お試しください",
        3
      );
    }
  },
};
</script>
<style scoped>
/*noinspection CssUnusedSymbol*/
.row {
  margin: initial;
}
.box {
  background-color:#fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  padding: 0 !important;
}
.content {
  min-height: 100vh;
  background-color: rgba(233, 215, 184, 0.45);
}
.header {
  height: 3rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
}
p {
  margin-bottom: 0;
}
p.caption {
  font-size: 0.625rem !important;
  line-height: 1rem !important;
}

.fontsize12 >>> label {
  font-size: 12px;
}
a {
  color: #333333;
}
</style>